import React, { useState, useEffect, useRef, useCallback } from "react";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { TiArrowBackOutline } from "react-icons/ti";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { Player, FS_SDK_EVENTS_NAME } from "furioos-sdk";
import { saveAs } from "file-saver";
import logoImage from "../../assets/eventrender.png";
import PurewebContainer from "./pureweb/pureweb";
import axios from "axios";
import { Tooltip } from "@mui/material";
import { useContext } from "react";
import { purewebContext } from "./../../../context/PurewebContext";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinearProgress from "@mui/material/LinearProgress";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Helmet } from "react-helmet";
import { Box, DialogContent, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";

const isImageFile = (fileType) => {
  // Regular expression pattern to match file extensions
  var imageExtensions = /\.(jpg|jpeg|png|bmp|gif|tiff|webp|heic)$/i;

  // Test if the fileType matches the imageExtensions pattern
  return imageExtensions.test(fileType);
};

const LocationModal = ({ onUsaClicked, onGlobalClicked }) => {
  return (
    <div className="location-modal--container">
      <div className="location-modal--header">Where are you?</div>
      <div className="location-modal--buttons">
        <div onClick={onUsaClicked} className="location-modal--button">
          <div>USA</div>
          <div className="location-modal--button--smalltext">
            ( Fast loading )
          </div>
        </div>
        <div onClick={onGlobalClicked} className="location-modal--button">
          <div>Other Countries</div>
          <div className="location-modal--button--smalltext">
            ( Can load slower )
          </div>
        </div>
      </div>
    </div>
  );
};

export const Upload3DContainer = ({ }) => {
  const { currentEmitter: currentPlayer, setUpload3DContainerVisible } =
    useContext(purewebContext);
  const [file, setFile] = useState(null);
  // const [previewImage, setPreviewImage] = useState(null)
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }, [errorMessage]);

  const clearAll = () => {
    setUpload3DContainerVisible(false);
    setFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    if (!file) {
      return setErrorMessage("File must be selected");
    }
    setUploading(true);
    const formData = new FormData();
    formData.append("3dFile", file);
    try {
      const userEmail = localStorage.getItem("userEmail");
      const response = await axios.post(
        `https://api.rendernow.co/upload3d/${userEmail}`,
        formData
      );
      clearAll();

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (e) {
      console.log(e);
      return setErrorMessage(e?.response?.data?.error?.message || "");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="image-upload-modal-1" style={{ minHeight: "unset" }}>
      <div className="image-upload-modal-cross-container">
        <div>
          <CloseIcon
            onClick={clearAll}
            size={25}
            style={{ cursor: "pointer" }}
          ></CloseIcon>
        </div>
      </div>
      <div className="image-upload-modal-2">Choose 3D File</div>
      <input
        ref={fileInputRef}
        className="image-upload-modal-4"
        type="file"
        onChange={handleFileChange}
      ></input>

      <button
        className="image-upload-modal-5"
        onClick={uploading ? () => { } : handleUpload}
      >
        {uploading ? "Uploading..." : "Upload"}
      </button>
      <div className="image-upload-modal-7">
        <div
          className="image-upload-modal--error"
          style={{ opacity: errorMessage.length > 0 ? 1 : 0 }}
        >
          {errorMessage ? errorMessage : "ERROR"}
        </div>
      </div>
    </div>
  );
};

export const ImgBBContainer = ({ }) => {
  const { currentEmitter: currentPlayer, setImgbbContainerVisible } =
    useContext(purewebContext);
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }, [errorMessage]);

  const clearAll = () => {
    setImgbbContainerVisible(false);
    setFile(null);
    currentPlayer?.EmitUIInteraction(`IMGBB_MODAL_CLOSE`);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!isImageFile(file.name)) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return setErrorMessage("File format is not supported.");
    }

    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert bytes to megabytes

    if (fileSizeInMB > 10) {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return setErrorMessage("File must not exceed 10MB");
    }

    setFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    if (!file) {
      return setErrorMessage("File must be selected");
    }
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "imgbb_replace");
    formData.append("cloud_name", "daicbqtlb");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/daicbqtlb/image/upload",
        formData
      );
      currentPlayer.EmitUIInteraction(
        `ImageLinkString,${response.data.secure_url}`
      );
      clearAll();

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (e) {
      console.log(e);
      return setErrorMessage(e?.response?.data?.error?.message || "");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="image-upload-modal-1">
      <div className="image-upload-modal-cross-container">
        <div>
          <CloseIcon
            onClick={clearAll}
            size={25}
            style={{ cursor: "pointer" }}
          ></CloseIcon>
        </div>
      </div>
      <div className="image-upload-modal-2">Choose Image</div>
      <input
        ref={fileInputRef}
        className="image-upload-modal-4"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      ></input>

      <div className="image-requirement-container">
        <ul>
          <li>* Maximum image size: 10MB</li>
          <li>
            * Image formats accepted: JPG, JPEG, PNG, BMP, GIF, TIFF, WEBP, or
            HEIC.
          </li>
          {/* <li>* Keep image sizes under 10MB for faster loading.</li> */}
        </ul>
      </div>

      <button
        className="image-upload-modal-5"
        onClick={uploading ? () => { } : handleUpload}
      >
        {uploading ? "Uploading..." : "Upload"}
      </button>
      <div className="image-upload-modal-7">
        <div className="image-upload-modal-8">
          {
            previewImage && (
              // <Tooltip title="Click to remove photo">
              <img src={previewImage} alt="Preview" />
            )
            // </Tooltip>
          }
        </div>

        <div
          className="image-upload-modal--error"
          style={{ opacity: errorMessage.length > 0 ? 1 : 0 }}
        >
          {errorMessage ? errorMessage : "ERROR"}
        </div>
      </div>
    </div>
  );
};

export const LoadingApplicationPage = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <h3 className="responsive-text-color">Loading Application...</h3>
    </div>
  );
};

export const LoginPage = ({
  currentPlayer,
  error,
  setError,
  loginLoading,
  setLoginLoading,
  success,
  setSuccess,
  resetPasswordLoading,
  setResetPasswordLoading,
  newAccountLoading,
  setNewAccountLoading,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [newAccountMode, setNewAccountMode] = useState(false);
  const [loginMode, setLoginMode] = useState(true);
  const { startTimer, stopTimer, setPlayfabData } = useContext(purewebContext);

  useEffect(() => {
    localStorage.setItem("userEmail", email);
  }, [email]);

  useEffect(() => {
    const inputField = document.getElementById("login_email_field");
    const passwordField = document.getElementById("login_password_field");
    function handleEvent(event) {
      if (event.key === "Tab") {
        passwordField?.focus?.();
      }
    }
    function handleEvent2(event) {
      if (event.key === "Tab") {
        inputField?.focus?.();
      }
    }

    inputField?.addEventListener("keydown", handleEvent);
    passwordField?.addEventListener("keydown", handleEvent2);

    return () => {
      inputField?.removeEventListener("keydown", handleEvent);
      passwordField?.removeEventListener("keydown", handleEvent2);
    };
  }, []);

  const fetchPlayfabPlayers = async ({ email, password }) => {
    try {
      const response = await axios.post(
        "https://api.rendernow.co/playerdetails",
        {
          email,
          password,
        }
      );
      setPlayfabData(response.data);
    } catch (e) {
      setPlayfabData([]);
    }
  };

  const handleLogin = useCallback(async () => {
    setError("");
    startTimer();

    try {
      if (currentPlayer) {
        setLoginLoading(true);
        currentPlayer.EmitUIInteraction(`LoginEmail,${email}`);
        currentPlayer.EmitUIInteraction(`LoginPassword,${password}`);
        await fetchPlayfabPlayers({ email, password });
      }
    } catch (e) {
      console.log("ERROR LOGGING IN");
      console.log(e);
      stopTimer();
    }
  }, [currentPlayer, email, password]);

  const handleResetPassword = useCallback(() => {
    setError("");
    setSuccess("");
    if (loginMode && !resetPasswordMode) {
      setLoginMode(false);
      setResetPasswordMode(true);
      return;
    }
    setResetPasswordLoading(true);
    currentPlayer.EmitUIInteraction(`ResetPassword,${email}`);
    console.log("Reset password SDK message sent");
  }, [currentPlayer, email, loginMode, resetPasswordMode]);

  const handleSignUp = useCallback(() => {
    setError("");
    setSuccess("");
    if (loginMode && !newAccountMode) {
      setLoginMode(false);
      setNewAccountMode(true);
      return;
    }
    setNewAccountLoading(true);
    currentPlayer.EmitUIInteraction(`NewUser,${email}`);
    console.log("New account SDK message sent");
  }, [currentPlayer, email, loginMode, newAccountMode]);

  const backToLogin = () => {
    setLoginLoading(false);
    setResetPasswordLoading(false);
    setNewAccountLoading(false);
    setLoginMode(true);
    setResetPasswordMode(false);
    setNewAccountMode(false);
    setEmail("");
    setPassword("");
    setSuccess("");
    setError("");
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-box--title">
          {loginMode
            ? "User Login"
            : resetPasswordMode
              ? "Reset Password"
              : "New Account"}
        </div>
        <div className="login-box--subtitle">
          {loginMode
            ? "Returning users login with your email and password. Otherwise, create a new account."
            : resetPasswordMode
              ? "Enter email below and we will send a reset password link."
              : "Enter email below to create a new account."}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
            position: "relative",
          }}
        >
          <input
            id="login_email_field"
            placeholder="Enter your email"
            value={email}
            type={"email"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoComplete="on"
            name="email"
          ></input>
          {loginMode && (
            <input
              id="login_password_field"
              placeholder="Enter your password"
              value={password}
              type={"password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              name="email"
            ></input>
          )}
          {error && (
            <div
              style={{
                // position: "absolute",
                color: "#e83232",
                textAlign: "left",
                // bottom: "-15px",
                // left: 0,
                marginTop: -15,
              }}
            >
              {error}
            </div>
          )}
          {success && (
            <div
              style={{
                // position: "absolute",
                color: "#55d655",
                textAlign: "left",
                marginTop: -15,
                // bottom: "-15px",
                // left: 0,
              }}
            >
              {success}
            </div>
          )}
        </div>
        {loginMode && (
          <button
            onClick={handleLogin}
            style={{ backgroundColor: "#0AAC41" }}
            disabled={loginLoading}
          >
            {loginLoading ? "Logging in..." : "Login"}
          </button>
        )}
        {(loginMode || resetPasswordMode) && (
          <button
            onClick={handleResetPassword}
            style={{ backgroundColor: "#959496" }}
            disabled={resetPasswordLoading}
          >
            {resetPasswordLoading
              ? "Sending Reset Password Email..."
              : "Reset Password"}
          </button>
        )}
        {(loginMode || newAccountMode) && (
          <button
            onClick={handleSignUp}
            style={{ backgroundColor: "#0069BA" }}
            disabled={newAccountLoading}
          >
            {newAccountLoading
              ? "Creating New Account..."
              : "Create New Account"}
          </button>
        )}
        {(resetPasswordMode || newAccountMode) && (
          <button onClick={backToLogin} style={{ backgroundColor: "#55d655" }}>
            Back to Login
          </button>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="login-box--logo"
        >
          <div style={{ maxWidth: 250 }}>
            <img
              style={{ zIndex: 2 }}
              src={logoImage}
              className="logo logo-display widthofimage"
              alt="Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoginPageAdmin = ({
  error,
  setError,
  loginLoading,
  setLoginLoading,
  setLoginVisible,
  setIsModalOpen,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setPlayfabData } = useContext(purewebContext);

  useEffect(() => {
    const inputField = document.getElementById("login_email_field_admin");
    const passwordField = document.getElementById("login_password_field_admin");
    function handleEvent(event) {
      if (event.key === "Tab") {
        passwordField?.focus?.();
      }
    }
    function handleEvent2(event) {
      if (event.key === "Tab") {
        inputField?.focus?.();
      }
    }

    inputField?.addEventListener("keydown", handleEvent);
    passwordField?.addEventListener("keydown", handleEvent2);

    return () => {
      inputField?.removeEventListener("keydown", handleEvent);
      passwordField?.removeEventListener("keydown", handleEvent2);
    };
  }, []);

  const fetchPlayfabPlayers = async ({ email, password }) => {
    try {
      const response = await axios.post(
        "https://api.rendernow.co/playerdetails",
        {
          email,
          password,
        }
      );
      setPlayfabData(response.data);
      setLoginVisible(false);
      if (response.data?.length > 0) {
        setIsModalOpen(true);
      }
    } catch (e) {
      setPlayfabData([]);
      setError(
        "Failed to login. Please check your credentials or contact support."
      );
    } finally {
      setLoginLoading(false);
    }
  };

  const handleLogin = useCallback(async () => {
    setError("");
    setLoginLoading(true);
    await fetchPlayfabPlayers({ email, password });
  }, [email, password]);

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-box--title">Admin Login</div>
        <div className="login-box--subtitle">Login to see user stats.</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
            position: "relative",
          }}
        >
          <input
            id="login_email_field_admin"
            placeholder="Enter your email"
            value={email}
            type={"email"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoComplete="on"
            name="email"
          ></input>
          <input
            id="login_password_field_admin"
            placeholder="Enter your password"
            value={password}
            type={"password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            name="email"
          ></input>

          {error && (
            <div
              style={{
                // position: "absolute",
                color: "#e83232",
                textAlign: "left",
                // bottom: "-15px",
                // left: 0,
                marginTop: -15,
              }}
            >
              {error}
            </div>
          )}
        </div>

        <button
          onClick={handleLogin}
          style={{ backgroundColor: "#0AAC41" }}
          disabled={loginLoading}
        >
          {loginLoading ? "Logging in..." : "Login"}
        </button>

        <button
          onClick={() => {
            setLoginVisible(false);
          }}
          style={{ backgroundColor: "#55d655" }}
        >
          Cancel
        </button>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="login-box--logo"
        >
          <div style={{ maxWidth: 250 }}>
            <img
              style={{ zIndex: 2 }}
              src={logoImage}
              className="logo logo-display widthofimage"
              alt="Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const DownloadBar = ({
  imageUrl,
  onDownloadClick,
  onCancel,
  preparingDownload,
  renderStarted,
  isFullScreen,
}) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus?.();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
      setCopied(true);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }

  const handleCopy = () => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(imageUrl);
      return;
    }
    window.navigator.clipboard
      .writeText(imageUrl)
      .then(function () {
        setCopied(true);
        console.log("Text copied to clipboard");
      })
      .catch(function (err) {
        alert("Failed to copy text");
        console.error("Unable to copy text to clipboard", err);
      });
  };

  return (
    <div className="download-image-popup--container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          opacity: renderStarted ? 0 : 1,
        }}
      >
        <div className="download-image-popup--image-container">
          <img className="download-image-popup--image" src={imageUrl}></img>
        </div>
        <div className="text-center" style={{ fontSize: "95%" }}>
          Image.png
        </div>
      </div>
      {!isFullScreen && (
        <ReactTooltip
          anchorSelect=".click-to-copy"
          id="my-tooltip-2"
          style={{ zIndex: 1000, marginTop: 16 }}
        />
      )}
      <div
        className={`${renderStarted ? "click-to-copy" : "copy-container click-to-copy"
          }`}
        onClick={renderStarted ? () => { } : handleCopy}
        data-tooltip-id={isFullScreen ? "my-tooltip" : "my-tooltip-2"}
        data-tooltip-content={
          copied
            ? "Copied"
            : "This URL will be valid for 5 days. Click to copy."
        }
        data-tooltip-place={isFullScreen ? "top" : "top"}
      >
        {!renderStarted && (
          <ContentCopyIcon
            className="copy-container-icon"
            sx={{ padding: 0.3 }}
          ></ContentCopyIcon>
        )}

        {!renderStarted && (
          <div
            className="copy-container--text"
            style={{ fontWeight: renderStarted ? "bold" : undefined }}
          >
            {imageUrl}
          </div>
        )}
      </div>
      <div
        style={{
          display: renderStarted ? "flex" : "none",
          flexDirection: "column",
          width: "100%",
          fontSize: 18,
          paddingBottom: 4,
          gap: 20,
        }}
      >
        {renderStarted && (
          <div style={{ textAlign: "center" }}>Rendering Image...</div>
        )}
        {renderStarted && <LinearProgress sx={{ width: "100%" }} />}
      </div>
      <div
        className="download-image-popup--button-container"
        style={{ opacity: renderStarted ? 0 : 1 }}
      >
        {!preparingDownload && (
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              // letterSpacing: 1.2,
              color: "red",
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "white",
                width: "auto",
              },
            }}
            onClick={onCancel}
          >
            <span className="bannerdec3">Cancel</span>
          </Button>
        )}

        <Button
          variant="contained"
          sx={{
            textTransform: "capitalize",
            // letterSpacing: 1.2,
            "&:hover": {
              backgroundColor: "white",
              color: "#1976d2",
              width: "auto",
            },
          }}
          onClick={preparingDownload ? () => { } : onDownloadClick}
        >
          <span className="bannerdec3">
            {preparingDownload ? "Downloading..." : "Download"}
          </span>
        </Button>
      </div>
    </div>
  );
};

export const MessagePopup = ({ onOkClicked, type }) => {
  return <></>
  return (
    <div className="network-message--container">
      <div style={{ textAlign: "center" }}>
        {type === "refresh" ? (
          <>
            Bad connection, please
            <span
              onClick={() => window.location.reload()}
              className="bannerdec3"
            >
              {" "}
              refresh
            </span>{" "}
            the page and reconnect.
          </>
        ) : (
          <>
            Possible inconsistent internet speeds detected. If you see
            consistent quality loss, please
            <span
              onClick={() => window.location.reload()}
              className="bannerdec3"
            >
              {" "}
              refresh
            </span>{" "}
            the page or switch to a different internet source.
          </>
        )}
      </div>
      <Button
        style={{ zIndex: 5 }}
        variant="contained"
        sx={{
          width: "10%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 2,
        }}
        onClick={onOkClicked}
      >
        OK
      </Button>
    </div>
  );
};

function Emulator() {
  const [open, setOpen] = useState(true);
  const loadedMessageReceivedRef = useRef(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { playfabData } = useContext(purewebContext);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      setLoginVisible((current) => !current);
    }

    if (event.key === "ArrowDown") {
      setConnectionSuccessLoadingApplication((current) => !current);
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "development") return;
    // Add event listeners when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const history = useHistory();
  // const [imagefileone, setImagefileone] = useState("");
  // const [setImagefiletwo] = useState("")
  const [imagedata, setImageData] = useState([]);
  const [loginVisible, setLoginVisible] = useState(false);
  const [loginVisibleAdmin, setLoginVisibleAdmin] = useState(false);
  const [error, setError] = useState("");
  const [errorAdmin, setErrorAdmin] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginLoadingAdmin, setLoginLoadingAdmin] = useState(false);
  const [success, setSuccess] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [newAccountLoading, setNewAccountLoading] = useState(false);
  const { currentEmitter, setCurrentEmitter } = useContext(purewebContext);
  const [locationChoice, setLocationChoice] = useState(null);
  const [loadingTimeLeft, setLoadingTimeLeft] = useState(15);
  const loadingTimeSetInterval = useRef(null);

  const {
    setUpload3DContainerVisible,
    setImgbbContainerVisible,
    setDownloadVisible,
    setPreparingDownload,
    setRenderStarted,
    setImagefileone,
    imagefileone,
    stopTimer,
    connectionSuccessLoadingApplication,
    setConnectionSuccessLoadingApplication,
    setRefreshRequiredModalVisible,
    userLoggedIn,
    setUserLoggedIn,
    streamQuality,
    setStreamQuality,
  } = useContext(purewebContext);

  useEffect(() => {
    if (connectionSuccessLoadingApplication) {
      loadingTimeSetInterval.current = setInterval(() => {
        setLoadingTimeLeft((prev) => prev - 1);
      }, 1000);
    }
  }, [connectionSuccessLoadingApplication]);

  useEffect(() => {
    if (loadingTimeLeft === 0) {
      setRefreshRequiredModalVisible(true);
    }
  }, [loadingTimeLeft]);

  useEffect(() => {
    const cb = function (e) {
      currentEmitter?.EmitUIInteraction(`CLOSED_BY_USER`);
      return;
    };
    window.addEventListener("beforeunload", cb);
    return () => {
      window.removeEventListener("beforeunload", cb);
    };
  }, [currentEmitter]);

  const refs = useRef("");
  const dashSelector = useSelector((state) => state.adminReducer);
  const handleMessage = (data) => {
    console.log("-----SDK Message Received:", data);
    if (data.length < 200) {
      let successful = false;
      if (data === "SDK_Login_Success") {
        stopTimer();
        setLoginVisible(false);
        setUserLoggedIn(true);
        setConnectionSuccessLoadingApplication(false);
        localStorage.setItem("render_now_furioos_login", true);
        successful = true;
      }

      if (data === "RenderNow_Loaded") {
        if (!loadedMessageReceivedRef.current) {
          loadedMessageReceivedRef.current = 1;
          setConnectionSuccessLoadingApplication(false);
          setLoginVisible(true);
          successful = true;
          if (loadingTimeSetInterval.current) {
            clearInterval(loadingTimeSetInterval.current);
          }
        }
      }

      if (data === "FullScreen_Toggle") {
        document.getElementById("full_screen_button")?.click?.();
      }

      if (data === "Add_Image_PopUp") {
        setImgbbContainerVisible(true);
      }

      if (data === "Add_3DFile_PopUp") {
        setUpload3DContainerVisible(true);
      }

      if (data === "Render_Image_Triggered") {
        setRenderStarted(true);
        setDownloadVisible(true);
      }

      if (data === "Password reset has been sent to your email successfully") {
        setSuccess(data);
        successful = true;
      }
      if (data.includes("Success")) {
        setSuccess(data);
        successful = true;
      }

      if (data.includes("https://api.rendernow.co/images/")) {
        // setImageData(data)
        setImagefileone(data);
        // setImagefiletwo(data);
        setRenderStarted(false);
        setDownloadVisible(true);
      }

      if (!successful) {
        setSuccess("");
        if (data !== "RenderNow_Loaded") {
          setError(data);
        }
      }
      if (data !== "RenderNow_Loaded") {
        setResetPasswordLoading(false);
        setLoginLoading(false);
        setNewAccountLoading(false);
      }
    } else {
      setImageData((oldval) => {
        console.log("values are ", oldval);
        if (oldval.length === 2) {
          return ["data:image/png;base64," + data];
        }
        return [...oldval, "data:image/png;base64," + data];
      });
    }
  };

  useEffect(() => {
    console.log("image are", imagedata);
    if (imagedata.length > 1) {
      setImagefileone(imagedata[0]);
      // setImagefiletwo(imagedata[1]);
    }
  }, [imagedata]);

  useEffect(() => {
    (async () => {
      let container = document.querySelector("#pureweb_container");
      // Function to handle mouseenter event
      function disableScroll() {
        // Disable scroll by setting overflow property to hidden
        document.body.style.position = "fixed";
        document.body.style.top = "0px";
        document.body.style.left = "0px";
        document.body.style.right = "0px";
        document.body.style.overflowY = "scroll";
      }

      // Function to handle mouseleave event
      function enableScroll() {
        // Enable scroll by setting overflow property to its default value
        document.body.style.position = "";
      }

      // Attach event listeners to the container
      container?.addEventListener("mouseenter", disableScroll);
      container?.addEventListener("mouseleave", enableScroll);

      // Cleanup function to remove event listeners
      return () => {
        container?.removeEventListener("mouseenter", disableScroll);
        container?.removeEventListener("mouseleave", enableScroll);
      };
    })();
    // Get the reference to the 'pureweb_container' element
  }, []); // Empty dependency array to run the effect only once on component mount

  useEffect(() => {
    const mondayformFeedbackContainer = document.querySelector(
      "#mondayform-feedback--container"
    );

    function handleScroll() {
      mondayformFeedbackContainer?.classList.remove("hide-me");
      document.removeEventListener("scroll", handleScroll);
    }

    document.addEventListener("scroll", handleScroll);
  }, []);

  async function downloadImage(imageSrc, nameOfDownload = "Image.png") {
    const response = await fetch(imageSrc);

    const blobImage = await response.blob();

    const href = URL.createObjectURL(blobImage);

    const anchorElement = document.createElement("a");
    anchorElement.href = href;
    anchorElement.download = nameOfDownload;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }

  const saveimage = async (name) => {
    if (name === "first" && imagefileone) {
      downloadImage(imagefileone, "Image.png")
        .then(() => {
          setPreparingDownload(false);
          setDownloadVisible(false);
          console.log("The image has been downloaded");
        })
        .catch((err) => {
          console.log("Error downloading image: ", err);
        });
    }
  };

  const getButtonStyle = (quality) => {
    return streamQuality === quality
      ? { backgroundColor: "#1976D2", color: "white" }
      : {};
  };

  return (
    <div>
      {/* <Helmet>
        <script>
          {`
        window.markerConfig = {
          project: '64f614afd15407380f5b7b40',
          source: 'snippet'
        };
      `}
        </script>
        <script>
          {`
        !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
      `}
        </script>
      </Helmet> */}
      <div className="main-container">
        <div
          id="pureweb_container"
          ref={refs}
          style={{ height: userLoggedIn ? undefined : "86vh" }}
        >
          {/* {!locationChoice ? (
            <LocationModal
              onUsaClicked={() => setLocationChoice("USA")}
              onGlobalClicked={() => {
                setLocationChoice("Global")
              }}
            />
          ) : (
            <PurewebContainer
              onConnectionSuccess={(isSuccess) => {
                if (isSuccess) {
                  setConnectionSuccessLoadingApplication(true)
                }
              }}
              onEmitterChange={(emitter) => {
                setCurrentEmitter(emitter)
              }}
              onPurewebMessageReceived={(message) => {
                handleMessage(message)
              }}
              locationChoice={locationChoice}
            ></PurewebContainer>
          )} */}
          <PurewebContainer
            onConnectionSuccess={(isSuccess) => {
              if (isSuccess) {
                setConnectionSuccessLoadingApplication(true);
              }
            }}
            onEmitterChange={(emitter) => {
              setCurrentEmitter(emitter);
            }}
            onPurewebMessageReceived={(message) => {
              handleMessage(message);
            }}
            locationChoice={"USA"}
          ></PurewebContainer>

          {loginVisible && (
            <LoginPage
              currentPlayer={currentEmitter}
              setImageData={setImageData}
              error={error}
              setError={setError}
              loginLoading={loginLoading}
              setLoginLoading={setLoginLoading}
              success={success}
              setSuccess={setSuccess}
              resetPasswordLoading={resetPasswordLoading}
              setResetPasswordLoading={setResetPasswordLoading}
              newAccountLoading={newAccountLoading}
              setNewAccountLoading={setNewAccountLoading}
            ></LoginPage>
          )}

          {loginVisibleAdmin && (
            <LoginPageAdmin
              error={errorAdmin}
              setError={setErrorAdmin}
              loginLoading={loginLoadingAdmin}
              setLoginLoading={setLoginLoadingAdmin}
              setLoginVisible={setLoginVisibleAdmin}
              setIsModalOpen={setIsModalOpen}
            ></LoginPageAdmin>
          )}
          {connectionSuccessLoadingApplication && <LoadingApplicationPage />}
        </div>

        {/* <div style={{ display: "flex", justifyContent: "center", gap: 10, marginBottom: 10 }}>
          <Button onClick={() => { setStreamQuality("sxga") }} style={getButtonStyle("sxga")}>SXGA</Button>
          <Button onClick={() => { setStreamQuality("hd") }} style={getButtonStyle("hd")}>HD</Button>
          <Button onClick={() => { setStreamQuality("hdplus") }} style={getButtonStyle("hdplus")}>HD Plus</Button>
          <Button onClick={() => { setStreamQuality("fhd") }} style={getButtonStyle("fhd")}>FHD</Button> */}
        {/* <Button onClick={() => { setStreamQuality("wuxga") }} style={getButtonStyle("wuxga")}>WUXGA</Button> */}
        {/* <Button onClick={() => { setStreamQuality("qhd") }} style={getButtonStyle("qhd")}>QHD</Button>
          <Button onClick={() => { setStreamQuality("wqhd") }} style={getButtonStyle("wqhd")}>WQHD</Button>
          <Button onClick={() => { setStreamQuality("uhd") }} style={getButtonStyle("uhd")}>UHD</Button>
        </div> */}

        <div
          className="text-center mt-lg-2 mt-5 h6 text-dark"
          style={{ fontSize: "95%" }}
        >
          When inactive for 20 minutes, the connection will automatically
          disconnect.
        </div>

        <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: 300,

              marginTop: 2,
            }}
            onClick={() => {
              // uncomment it
              // dispatch({ type: "allow" });
              history.push("/dashboard");
            }}
          >
            Go back to Dashboard
          </Button>
          {playfabData.length ? (
            <>
              <Button
                variant="contained"
                sx={{
                  width: 300,
                  marginTop: 2,
                }}
                onClick={() => setIsModalOpen(true)}
              >
                User stats
              </Button>

              <Dialog
                open={isModalOpen}
                fullWidth={true}
                disableScrollLock={true}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
              // sx={{
              //   width: window.innerWidth <= 760 ? "68%" : "100%",
              //   paddingRight:
              //     window.innerWidth <= 1280 && window.innerWidth >= 740 && "15%",
              // }}
              >
                <DialogContent>
                  <Box>
                    <Typography variant="h6" align="center" gutterBottom>
                      User stats
                    </Typography>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              whiteSpace: "nowrap",
                              padding: "8px",
                              backgroundColor: "#f2f2f2",
                              textAlign: "center",
                            }}
                          >
                            Display Name
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              whiteSpace: "nowrap",
                              padding: "8px",
                              backgroundColor: "#f2f2f2",
                              textAlign: "center",
                            }}
                          >
                            New Project Count
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              whiteSpace: "nowrap",
                              padding: "8px",
                              backgroundColor: "#f2f2f2",
                              textAlign: "center",
                            }}
                          >
                            New Project Count (Current Month)
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              whiteSpace: "nowrap",

                              padding: "8px",
                              backgroundColor: "#f2f2f2",
                              textAlign: "center",
                            }}
                          >
                            Render Count
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              whiteSpace: "nowrap",
                              padding: "8px",
                              backgroundColor: "#f2f2f2",
                              textAlign: "center",
                            }}
                          >
                            Render Count (Current Month)
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              whiteSpace: "nowrap",
                              padding: "8px",
                              backgroundColor: "#f2f2f2",
                              textAlign: "center",
                            }}
                          >
                            Total Time Spent
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              whiteSpace: "nowrap",
                              padding: "8px",
                              backgroundColor: "#f2f2f2",
                              textAlign: "center",
                            }}
                          >
                            Total Time Spent (Current Month)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {playfabData
                          .sort((a, b) => {
                            if (!a.DisplayName && !b.DisplayName) {
                              return 0;
                            }
                            if (!a.DisplayName) {
                              return 1;
                            }
                            if (!b.DisplayName) {
                              return -1;
                            }
                            return a.DisplayName.localeCompare(b.DisplayName);
                          })
                          .map((data) => (
                            <tr key={data.PlayerId}>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {data.DisplayName || "-"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {data.data?.Data?.NewProject_Count?.Value ??
                                  "-"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {data.ThisMonthNewProjectCount ?? "-"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",

                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {data.data?.Data?.Render_Count?.Value ?? "-"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {data.ThisMonthRenderCount ?? "-"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {data.data?.Data?.total_time_spent?.Value ??
                                  "-"}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                {data.ThisMonthTotalTimeSpent ?? "-"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Box>
                </DialogContent>
              </Dialog>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="imagecontainer">
          {/* <div id="captureImg">Rendering</div> */}

          <div id="captureImg">
            {!imagefileone ? (
              "Rendering"
            ) : (
              <img src={imagefileone} alt="renders" />
            )}
          </div>
        </div>
        <div className="downloadbutton">
          <div>
            <button id="download" onClick={() => saveimage("first")}>
              Download Image
            </button>
          </div>
          {/* <div>
            <button id="download1080" onClick={() => saveimage("second")}>
              Disclaimer
            </button>
          </div> */}
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {!playfabData.length ? (
            <button
              style={{
                width: 300,
                backgroundColor: "#4CAF50", // Green background
                color: "white", // White text
                marginBottom: 10,
                borderRadius: 6,
                padding: 10,
                border: "none", // Removes the default border
                cursor: "pointer", // Changes the cursor to pointer on hover
                fontSize: "16px", // Slightly larger font size
              }}
              onClick={() => {
                setLoginVisibleAdmin(true);
                window.scrollTo(0, 0);
              }}
            >
              Admin Login
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* <div className="alagcss hide-me" id='mondayform-feedback--container'>
        <iframe
          title="feedback"
          src={
            dashSelector.renderSection.form === ""
              ? "https://forms.monday.com/forms/embed/ed24ff35b9056bc8ee13f920d6c5ee74?r=use1"
              : dashSelector.renderSection.form
          }
          height="500"
          style={{ width: "100%" }}
        ></iframe>
      </div> */}
    </div>
  );
}

export { Emulator };
