import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./assetsSRC/styles.css";
import { legacy_createStore as createStore } from "redux";
import { BrowserRouter } from "react-router-dom"
// import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import reducers from "./components/reduxstore/reduxis";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/themify-icons.css";
import "./assets/css/elegant-icons.css";
import "./assets/css/flaticon-set.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/animate.css";
import "./assets/css/bootsnav.css";
import "./style.css";
import "./assets/css/responsive.css";
import 'semantic-ui-css/semantic.min.css'
import 'react-circular-progressbar/dist/styles.css';

let store = createStore(reducers);

function Mainfun() {
  useEffect(() => {
    if (localStorage.getItem("hasCodeRunBefore") === null) {
      window.location.reload(true);
    }
    localStorage.setItem("hasCodeRunBefore", true);
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </Provider>
  );
}

ReactDOM.render(<Mainfun />, document.getElementById("roots"));
